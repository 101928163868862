import { createAction, props } from '@ngrx/store';
import { List } from 'src/app/core/models/app.models';

export const stepOneFormValid = createAction(
  '[Edit List Effects] Step One Form Valid',
);
export const stepTwoFormValid = createAction(
  '[Edit List Effects] Step Two Form Valid',
);
export const stepOneFormInvalid = createAction(
  '[Edit List Effects] Step One Form Invalid',
);
export const stepTwoFormInvalid = createAction(
  '[Edit List Effects] Step Two Form Invalid',
);
export const editListSuccessful = createAction(
  '[Edit List Effects] Edit List Successful',
);
export const editListUnsuccessful = createAction(
  '[Edit Listt Effects] Edit List Unsuccessful',
);
export const editListStarted = createAction(
  '[Edit List Effects] Edit List Started',
);
export const editListPostponed = createAction(
  '[Edit List Effects] Edit List Postponed',
);
export const listInviteesIsOverMaxInvitees = createAction(
  '[Edit List Effects] List Invitees is more than the maxInvitees',
);
