import { createAction, props } from '@ngrx/store';
import { EventInformation } from 'src/app/core/models/app.models';

export const listTypeClicked = createAction(
  '[Edit List Container] List Type Clicked',
);
export const editListClicked = createAction(
  '[Edit List Container] Edit List Clicked',
);
export const lastStepClicked = createAction(
  '[Edit List Container] Last Step Clicked',
);

export const backClicked = createAction(
  '[My List Container] Back Clicked',
  props<{
    event: EventInformation;
  }>(),
);
