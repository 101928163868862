import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { MyEventsContainerActions } from '../../../core/store/actions';
import { EditListContainerActions, EditListEffectsActions } from './index';
import { EditListState } from './edit-list.state';
import { OrbitContainerActions } from '../../../core/store/actions/index';
import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<EditListState> = {
  currentStep: 1,
  list: {
    id: 0,
    name: '',
    maxInvitees: 0,
    price: '',
    namePromoter: '',
    emailPromoter: '',
    link: '',
    validTillDate: '',
    validTillTime: '',
    listType: null,
    event: null,
    listInvitee: [],
  },
  eventInformation: {
    name: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    ticketLink: '',
    internalLabel: -1,
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    fyraTicketLink: '',
    location: 0,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    eventId: undefined,
    genres: [],
    tags: [],
    flyer: null,
  },
};

export const editListReducers = createReducer(
  initialState,
  on(MyEventsContainerActions.editEventClicked, (state, { event }) => ({
    ...state,
    currentStep: 1,
    eventInformation: mapEventToEventInformation(event),
  })),
  on(OrbitContainerActions.editListClicked, (state, { list }) => ({
    ...state,
    currentStep: 1,
    list: list,
    eventInformation: list!.event!,
  })),
  on(EditListContainerActions.editListClicked, (state) => ({
    ...state,
    currentStep: 1,
    list: state.list,
    eventInformation: state.list!.event!,
  })),
  on(EditListContainerActions.listTypeClicked, (state) => ({
    ...state,
    currentStep: 2,
  })),
  on(EditListContainerActions.lastStepClicked, (state) => ({
    ...state,
    currentStep: state.currentStep - 1,
  })),
  on(EditListEffectsActions.listInviteesIsOverMaxInvitees, (state) => ({
    ...state,
    currentStep: 2,
  })),
  on(EditListContainerActions.backClicked, (state) => ({
    ...state,
    currentStep: 1,
  })),
);

export const editListFeature = createFeature({
  name: 'editList',
  reducer: editListReducers,
});
