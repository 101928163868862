import * as EditListContainerActions from './actions/edit-list-container.actions';
import * as EditListEffectsActions from './actions/edit-list-effects.actions';
import { editListFeature } from './edit-list.reducers';

export * as editListEventState from './edit-list.state';

const { reducer, name, ...editListSelectors } = editListFeature;

export {
  reducer as editListReducer,
  name as editListName,
  editListSelectors,
  EditListContainerActions,
  EditListEffectsActions,
};
